jQuery(function($){

	function tokenRefresh() {
		var tokenFields = $('input[name=_token]');
		if (tokenFields.length) {
			return $.get('/ajax/csrf').success(function(data) {
				tokenFields.val(data);
			});
		}
	}

	// Refresh on load just in case the user pressed the back button.
	tokenRefresh();

	// Refresh token every 10 minutes
	var interval = setInterval(tokenRefresh, 1000 * 60 * 10);
});